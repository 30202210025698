import React, {Component} from 'react';
import Footer from '../components/Footer';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {signUp} from '../store/actions/AuthAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style/register.css";

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nama : '',
      nik : '',
      tempat_lahir : '',
      tanggal_lahir : new Date(),
      nomor_telepon : '',
      email : '',
      password : '',
      startDate: new Date()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);
    this.props.signUp(this.state,this.props.history)
  };

  handleChange = (e) => {
    this.setState ({
      [e.target.id] : e.target.value
    })
  };

  handleChangeDate = date => {
    this.setState({
      tanggal_lahir: date
    });
  };

  render() {
    const {authResponse} = this.props;
    return (
      <React.Fragment>
        <div className="container">
          <div className="login-screen row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <form onSubmit={this.handleSubmit}>
                <div className="login-container">
                  <div className="row no-gutters">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                      <div className="login-box">
                        <Link to="#" className="login-logo"><img src="/logoannisa.png" alt="Unify Admin Dashboard" /></Link>
                        <center><b>{authResponse!==null && authResponse!==""?authResponse:null}</b></center><br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-business-card"></i></span>
                          <input type="text" id="nik" className="form-control" placeholder="NIK" onChange={this.handleChange} />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-account_circle"></i></span>
                          <input type="text" id="nama" className="form-control" placeholder="Fullname" onChange={this.handleChange} />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-home"></i></span>
                          <input type="text" id="tempat_lahir" className="form-control" placeholder="Tempat Lahir" onChange={this.handleChange} />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-clock"></i></span>
                          <DatePicker id="tanggal_lahir" className="form-control-date" placeholderText="Tanggal Lahir" dateFormat="dd/MM/yyyy" showMonthDropdown showYearDropdown dropdownMode="select" maxDate={new Date()} selected={this.state.tanggal_lahir} onChange={this.handleChangeDate} />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-phone2"></i></span>
                          <input type="text" id="nomor_telepon" className="form-control" placeholder="No. HP" onChange={this.handleChange} />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-email"></i></span>
                          <input type="email" id="email" className="form-control" placeholder="Email ID" onChange={this.handleChange} />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-verified_user"></i></span>
                          <input type="password" id="password" className="form-control" placeholder="Password" onChange={this.handleChange} />
                        </div>
                        <div className="actions clearfix">
                          <button type="submit" className="btn btn-primary">Signup</button>
                        </div>
                        <div className="or"></div>
                        <div className="mt-4">
                          <Link to="/login" className="additional-link">Already have an Account? <span>Login Now</span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                      <div className="signup-slider"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authResponse: state.auth.authResponse
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds,history) => dispatch(signUp(creds,history))
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Register));
