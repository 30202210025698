import HttpServices from './HttpServices';

export const SignUpService = (credentials,propsHistory) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let signUpUrl = "user/register";
  return http.postData(credentials, signUpUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}

export const LoginUser = (credentials,propsHistory) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let signUpUrl = "user/login";
  return http.postData(credentials, signUpUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}

export const DataUser = () => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let profileUrl = "user/profile";
  return http.getData(profileUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}
