import HttpServices from './HttpServices';

export const AppointmentService = (data,propsHistory) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let appointmentUrl = "appointment/registrasi";
  return http.postData(data, appointmentUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}

export const ListAppointmentService = () => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let appointmentUrl = "appointment/all_registrasi";
  return http.getData(appointmentUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}
