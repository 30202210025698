// import React from 'react';
import {AsesmenAwalService} from '../services/AsesmenAwalService';

export const addAssesmen = (data,history) => {

  return (dispatch) => {

    AsesmenAwalService(data,history).then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'ASSESMEN_AWAL_SUCCESS'});
          history.push("/home/historiappointment");
        }else {
          dispatch({type: 'ASSESMEN_AWAL_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}
