import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Header extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <header className="app-header">
        <div className="container-fluid">
          <div className="row gutters">
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-3 col-4">
              <Link to="#" className="mini-nav-btn" id="app-side-mini-toggler">
                <i className="icon-menu5"></i>
              </Link>
              <Link to="#app-side" data-toggle="onoffcanvas" className="onoffcanvas-toggler" aria-expanded="true">
                <i className="icon-chevron-thin-left"></i>
              </Link>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-4">
              <Link to="/home" className="logo">
                <img src="/logoannisa.png" alt="Unify Admin Dashboard" />
              </Link>
						</div>

            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-3 col-4">
              <ul className="header-actions">
                <li className="dropdown">
                  <Link to="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                    <img className="avatar" src="/assets/img/user.png" alt="User Thumb" />
                    <span className="user-name"></span>
                    <i className="icon-chevron-small-down"></i>
                  </Link>
                  <div className="dropdown-menu lg dropdown-menu-right" aria-labelledby="userSettings">
                    <ul className="user-settings-list">
                      <li>
                        <Link to="/home/profil">
                          <div className="icon">
                            <i className="icon-account_circle"></i>
                          </div>
                          <p>Profile</p>
                        </Link>
                      </li>
                    </ul>
                    <div className="logout-btn">
                      <Link to="#" onClick={this.props.logOutHandler} className="btn btn-primary">
                        Logout
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
