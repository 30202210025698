class HttpServices {

  // url = "http://localhost:8000";
  // url = "http://portal.test";
  url = "http://apps2.rsannisa.co.id:65224/telemedisapi/";

  postData = async(item, added_url) => {
    const token = await localStorage.getItem('user');
    let requestOptions = {
      method: 'POST',
      headers: {
        'Authorization' : token,
        'Content-Type' : 'application/json',
      },
      body : JSON.stringify(item)
    };

    return fetch(this.url+"/"+added_url, requestOptions).then(
      response=>response.json()
    );

  }

  getData = async(added_url) => {
    const token = await localStorage.getItem('user');
    let requestOptions = {
      method: 'GET',
      headers: {
        'Authorization' : token,
        'Content-Type' : 'application/json',
      }
    };

    return fetch(this.url+"/"+added_url, requestOptions).then(
      response=>response.json()
    );
  }

}

export default HttpServices;
