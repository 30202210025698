const initState = {
  appointmentResponse : null,
  appointment : [],
  listAppointment : [],
  konsultasi : null
};

const AppointmentReducer = (state=initState, action) => {
  switch(action.type){
    case 'APPOINTMENT_SUCCESS':
      // console.log(action.res.registrasi);
      return {
        ...state,
        appointment : action.res.registrasi,
        appointmentResponse : "Registrasi added successfully"
      };

    case 'APPOINTMENT_ERROR':
      // console.log(action);
      return {
        ...state,
        appointmentResponse : "Cannot add Registrasi"
      };

    case 'LIST_APPOINTMENT_SUCCESS':
      // console.log(action);
      return {
        ...state,
        listAppointment : Array.from(action.res.all_registrasi),
        appointmentResponse : "Data Registrasi Berhasil didapatkan."
      };

    case 'LIST_APPOINTMENT_ERROR':
      // console.log(action);
      return {
        ...state,
        appointmentResponse : "Cannot add Registrasi"
      };

    case 'SET_APPOINTMENT_SUCCESS':
      // console.log(action.data_registrasi);
      return {
        ...state,
        appointment : action.data_registrasi,
        appointmentResponse : "Data Registrasi Berhasil didapatkan."
        };

    case 'SET_KONSULTASI':
      // console.log(action.data_registrasi);
      return {
        ...state,
        konsultasi : action.data_registrasi,
        appointmentResponse : "Siap Konsultasi..."
        };

    default:
      return state;
    }
  }

  export default AppointmentReducer;
