import React, {Component} from 'react';
import "../../style/jadwal.css";

class DaftarSpesialis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jadwalspesialis : null,
    }
  }

  render() {
    // const spesialis = this.props.value;
    return (
      <React.Fragment>
        <span className="badge badge-primary" onClick={this.props.clickHandler.bind(this, this.props.value)}>{this.props.children}</span>
      </React.Fragment>
    );
  }
}

export default DaftarSpesialis;
