import React from 'react';
// import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Master from './pages/Master';
import Login from './pages/Login';
import Register from './pages/Register';
import PrivateRoute from './pages/PrivateRoute';
import Konsultasi from './pages/Konsultasi';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/"><Login /></Route>
          <Route path="/login"><Login /></Route>
          <Route path="/register"><Register /></Route>
          <Route path="/konsul"><Konsultasi /></Route>
          <PrivateRoute path="/home" component={Master} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
