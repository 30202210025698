const initState = {
  spesialis : null,
  dokterspesialis : [],
  jadwalspesialis : [],
  jadwaldokter  : [],
  jadwalterpilih : null,
  dokterterpilih : null,
  jadwalResponse : null,
};

const JadwalReducer = (state=initState, action) => {
  switch(action.type){
    case 'GET_SPESIALIS_SUCCESS':
      return {
        ...state,
        spesialis : action.res.spesialis,
        jadwalResponse : 'Data spesialis berhasil didapat.'
      };

    case 'GET_JADWAL_SPESIALIS_SUCCESS':
      return {
        ...state,
        jadwalspesialis : Array.from(action.res.jadwal),
        jadwalResponse : 'Data jadwal spesialis berhasil didapat.'
      };

    case 'GET_JADWAL_SPESIALIS_ERROR':
      return {
        ...state,
        jadwalResponse : action.res.message
      };

    case 'GET_SPESIALIS_ERROR':
      return {
        ...state,
        jadwalResponse : action.res.message
      };

    case 'GET_DOKTER_SPESIALIS_SUCCESS':
    // console.log(action.res.dokter);
      return {
        ...state,
        dokterspesialis : Array.from(action.res.dokter),
        jadwalResponse : 'Data jadwal spesialis berhasil didapat.'
      };

    case 'GET_DOKTER_SPESIALIS_ERROR':
      return {
        ...state,
        dokterspesialis : [],
        jadwalResponse : action.res.message
      };

    case 'GET_JADWAL_DOKTER_SUCCESS':
      return {
        ...state,
        jadwaldokter : Array.from(action.res.jadwaldokter),
        jadwalResponse : 'Data jadwal spesialis berhasil didapat.'
      };

    case 'GET_JADWAL_DOKTER_ERROR':
      return {
        ...state,
        jadwaldokter : [],
        jadwalResponse : action.res.message
      };

    case 'GET_JADWAL_TERPILIH':
      return {
        ...state,
        jadwalterpilih : action.jadwal,
        dokterterpilih : action.dokter
      };

    case 'CODE_ERROR':
      return {
        ...state,
        jadwalResponse : 'There seems to be a problem please try again later.'
      };

    default:
      return state;

  }
}

export default JadwalReducer;
