import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getProfile} from '../../store/actions/AuthAction';
import {addAppointment} from '../../store/actions/AppointmentAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../style/register.css";

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jadwalterpilih : [],
      dokterterpilih : [],
      nama : "",
      nik : "",
      tempat_lahir : "",
      tanggal_lahir : "",
      nomor_telepon : "",
      no_kartu : "",
      id_jadwal : "",
      tanggal_registrasi : new Date(),
      berobat : false,
    }
  }

  componentDidMount () {
    // console.log(this.props.profile);
    this.props.getProfile();
    if (this.props.jadwalterpilih === null) {
      this.props.history.push('/home/jadwal');
    }
  }

  componentWillReceiveProps(items) {
    this.setState({
      id_jadwal: items.jadwalterpilih.id,
      jadwalterpilih: items.jadwalterpilih,
      dokterterpilih: items.dokterterpilih,
      nama : items.profile.nama,
      nik : items.profile.nik,
      tempat_lahir : items.profile.tempat_lahir,
      tanggal_lahir : items.profile.tanggal_lahir,
      nomor_telepon : items.profile.nomor_telepon,
      no_kartu : items.profile.no_rm,
    });

    if (items.profile.no_rm !== null && items.profile.no_rm !== "") {
      this.setState({
        berobat: true,
      });
    }else {
      this.setState({
        berobat: false,
      });
    }

  }

  handleChange = (e) => {
    this.setState ({
      [e.target.id] : e.target.value
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addAppointment(this.state,this.props.history)
  };

  dateFormat = (date) => {
    let month = date.getMonth()+1;
    let day = String(date.getDate()).padStart(2, '0');
    let year = date.getFullYear();

    if(day<10) {day='0'+day;}

    if (month<10){month='0'+month;}

    return day  + '/'+ month  + '/' + year;
  };

  handleChangeDate = date => {
    this.setState({
      tanggal_lahir: date
    });
  };

  handleCheckbox= (e) => {
    this.setState({
      berobat: e.target.checked
    });
    if (e.target.checked === false) {
      this.setState({
        no_kartu: ""
      });
    }else {
      this.setState({
        no_kartu: this.props.profile.no_rm
      });
    }
  };

  handleChangeNumberField = (e) => {
        let field_value = e.target.value;

        if (!Number(field_value) && field_value) {
            return;
        }

        this.setState({
            [e.target.id]: field_value
        });
    };

render() {
  let dokterterpilih = this.state.dokterterpilih;
  let jadwalpraktek = this.state.jadwalterpilih.tanggal_praktek + " ( "+
  this.state.jadwalterpilih.waktu_mulai+" s/d "+
  this.state.jadwalterpilih.waktu_selesai+" )";
  let id_jadwal = this.state.jadwalterpilih.id;
  let profile = {
    nik: "",
    nama: "",
    tempat_lahir: "",
    no_rm: "",
    tanggal_lahir: "",
    nomor_telepon: "",
    tanggal_registrasi: "",
  };
  // console.log(profile.no_rm);
  if (this.state.profile != null) {
    profile = this.state.profile;
  }

  return (
    <div className="app-main">
      <header className="main-heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
              <div className="page-icon">
                <i className="icon-laptop_windows"></i>
              </div>
              <div className="page-title">
                <h5>Appointment</h5>
                <h6 className="sub-heading">Buat Janji dengan dokter</h6>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="main-content">
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div className="card-header">Appointment</div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                  <div className="row no-gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>NIK</label>
                        <input type="text" className="form-control" id="nik" name="nik" value={this.state.nik || ""} onChange={this.handleChange}  placeholder="NIK" />
                      </div>
                      <div className="form-group">
                        <label>Nama</label>
                        <input type="text" className="form-control" id="nama" name="nama" value={this.state.nama || ''} onChange={this.handleChange}  placeholder="Nama" />
                      </div>
                      <div className="form-group">
                        <label>Tempat Lahir</label>
                        <input type="text" className="form-control" id="tempat_lahir" name="tempat_lahir" value={this.state.tempat_lahir || ''} onChange={this.handleChange}  placeholder="Tempat Lahir" />
                      </div>
                      <div className="form-group">
                        <label>Tanggal lahir</label>
                        <DatePicker id="tanggal_lahir" className="form-control-date" placeholderText="Tanggal Lahir" dateFormat="dd/MM/yyyy" value={this.state.tanggal_lahir || new Date()} showMonthDropdown showYearDropdown dropdownMode="select" maxDate={new Date()} selected={new Date()} onChange={this.handleChangeDate} />
                      </div>
                      <div className="form-group">
                        <label>No. Telp/HP</label>
                        <input type="text" className="form-control" id="nomor_telepon" name="nomor_telepon" value={this.state.nomor_telepon || ''} onChange={this.handleChange}  placeholder="No. Telp/HP" />
                      </div>
                      <hr/>
                      <div className="form-group">
                        <label>Tanggal Registrasi</label>
                        <input type="text" className="form-control" id="tanggal_registrasi" name="tanggal_registrasi" value={this.dateFormat(this.state.tanggal_registrasi)} placeholder="Tanggal Registrasi" readOnly />
                      </div>
                      <div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" value="" onChange={this.handleCheckbox} checked={this.state.berobat} />
													 Apakah sudah pernah berobat di RS AN-NISA Tangerang?
												</label>
											</div>
                      <div className="form-group">
                        <label>No. Kartu Berobat</label>
                        <input type="text" className="form-control" id="no_kartu" name="no_kartu" value={this.state.no_kartu} placeholder="No. Kartu" onChange={this.handleChangeNumberField} readOnly={this.state.berobat === false ? true : false} />
                      </div>
                      <div className="form-group">
                        <label>Spesialis</label>
                        <input type="text" className="form-control" id="spesialis" name="spesialis" placeholder="Spesialis" value={dokterterpilih.spesialis || ''} readOnly />
                      </div>
                      <div className="form-group">
                        <label>Nama Dokter</label>
                        <input type="hidden" className="form-control" id="id_jadwal" name="id_jadwal" value={id_jadwal || ''}  onChange={this.handleChange}  />
                        <input type="text" className="form-control" id="nama_dokter" name="nama_dokter" value={dokterterpilih.dokter || ''} placeholder="Nama Dokter" readOnly />
                      </div>
                      <div className="form-group">
                        <label>Tanggal Konsul</label>
                        <input type="text" className="form-control" id="tanggal_praktek" name="tanggal_praktek" value={jadwalpraktek || ''} placeholder="Tanggal Praktek" readOnly />
                      </div>
                      <div className="actions clearfix">
                        <button type="submit" className="btn btn-primary">Simpan</button>
                      </div>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

}

const mapStateToProps = (state) => {
  return {
    profile : state.auth.profile,
    jadwalterpilih : state.jadwaldokter.jadwalterpilih,
    dokterterpilih : state.jadwaldokter.dokterterpilih
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAppointment: (data,history) => dispatch(addAppointment(data,history)),
    getProfile: () => dispatch(getProfile())
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Appointment));
