// import React, { useState, useEffect } from 'react';
import {getSpesialisService} from '../services/JadwalService';
import {getJadwalSpesialisService} from '../services/JadwalService';
import {getDokterSpesialisService} from '../services/JadwalService';
import {getJadwalDokterService} from '../services/JadwalService';

export const getSpesialis = () => {
  return (dispatch) => {
    getSpesialisService().then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'GET_SPESIALIS_SUCCESS', res});
        }else {
          dispatch({type: 'GET_SPESIALIS_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const getJadwalSpesialis = (id_spesialis) => {
  return (dispatch) => {
    getJadwalSpesialisService(id_spesialis).then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'GET_JADWAL_SPESIALIS_SUCCESS', res});
        }else {
          dispatch({type: 'GET_JADWAL_SPESIALIS_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const getDokterSpesialis = (id_spesialis) => {
  return (dispatch) => {
    getDokterSpesialisService(id_spesialis).then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'GET_DOKTER_SPESIALIS_SUCCESS', res});
        }else {
          dispatch({type: 'GET_DOKTER_SPESIALIS_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const getJadwalDokter = (id_dokter) => {
  return (dispatch) => {
    getJadwalDokterService(id_dokter).then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'GET_JADWAL_DOKTER_SUCCESS', res});
        }else {
          dispatch({type: 'GET_JADWAL_DOKTER_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const getJadwalTerpilih = (jadwal,dokter) => {
  return (dispatch) => {
    dispatch({type: 'GET_JADWAL_TERPILIH', jadwal, dokter});
  }
}
