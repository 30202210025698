// import React from 'react';
import {AppointmentService} from '../services/AppointmentService';
import {ListAppointmentService} from '../services/AppointmentService';

export const addAppointment = (data,history) => {

  return (dispatch) => {
    AppointmentService(data,history).then(async (res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          await dispatch({type: 'APPOINTMENT_SUCCESS', res});
          await history.push("/home/asesmenawal");
        }else {
          dispatch({type: 'APPOINTMENT_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const listAppointment = () => {

  return (dispatch) => {
    ListAppointmentService().then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'LIST_APPOINTMENT_SUCCESS', res});
        }else {
          dispatch({type: 'LIST_APPOINTMENT_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const setAppointment = (data_registrasi) => {

  return (dispatch) => {
    dispatch({type: 'SET_APPOINTMENT_SUCCESS', data_registrasi});
  };
}

export const setKonsultasi = (data_registrasi) => {

  return (dispatch) => {
    dispatch({type: 'SET_KONSULTASI', data_registrasi});
  };
}
