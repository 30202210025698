import React, {Component} from 'react';
import DetailJadwal from './detailjadwal';
// import {getJadwalDokter} from '../../store/actions/JadwalAction';
// import {connect} from 'react-redux';
import "../../style/jadwal.css";

class DaftarJadwal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hari1 : '',
      hari2 : '',
      hari3 : '',
      hari4 : '',
    }
  }

  componentDidMount() {
    // console.log(this.props.dokter.length);
    var date1 = new Date(new Date().getTime());
    var date2 = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var date3 = new Date(new Date().getTime() + (24*2) * 60 * 60 * 1000);
    var date4 = new Date(new Date().getTime() + (24*3) * 60 * 60 * 1000);
    var hari1 = getDayName(date1)+" "+("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
    var hari2 = getDayName(date2)+" "+("0" + date2.getDate()).slice(-2) + "/" + ("0" + (date2.getMonth() + 1)).slice(-2) + "/" + date2.getFullYear();
    var hari3 = getDayName(date3)+" "+("0" + date3.getDate()).slice(-2) + "/" + ("0" + (date3.getMonth() + 1)).slice(-2) + "/" + date3.getFullYear();
    var hari4 = getDayName(date4)+" "+("0" + date4.getDate()).slice(-2) + "/" + ("0" + (date4.getMonth() + 1)).slice(-2) + "/" + date4.getFullYear();

    this.setState({
      hari1: hari1,
      hari2: hari2,
      hari3: hari3,
      hari4: hari4,
    })
  }

  render() {
    return (
      <React.Fragment>
      <h5>Jadwal Spesialis {this.props.spesialis}</h5>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Dokter</th>
              <th>{this.state.hari1}</th>
              <th>{this.state.hari2}</th>
              <th>{this.state.hari3}</th>
              <th>{this.state.hari4}</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(this.props.children).map((detailjadwal, index) => (
              <tr key={index}>
                <td>{detailjadwal.nama}</td>
                <DetailJadwal clickHandler={this.props.clickHandler} spesialis={this.props.spesialis} dokter={detailjadwal.nama}>{detailjadwal.jadwal}</DetailJadwal>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </React.Fragment>
    );
  }
}

function getDayName(date) {
  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  let dayName = days[date.getDay()];

  return dayName;
}

export default DaftarJadwal;
