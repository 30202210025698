import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getSpesialis, getJadwalSpesialis} from '../../store/actions/JadwalAction';
import {getDokterSpesialis, getJadwalDokter} from '../../store/actions/JadwalAction';
import {getProfile} from '../../store/actions/AuthAction';
import {getJadwalTerpilih} from '../../store/actions/JadwalAction';
import DaftarSpesialis from './daftarspesialis';
import DaftarJadwal from './daftarjadwal';

class Jadwal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      spesialis : 'test',
      jadwal : '',
      jadwalspesialis : [],
      dokterspesialis : [],
      jadwaldokter  : [],
      aktifspesialis : ''
    }
  }

  componentDidMount () {
    this.props.getSpesialis();
  }

  componentWillReceiveProps(items) {
    this.setState({
      spesialis: items.spesialis,
      jadwalspesialis: items.jadwalspesialis,
      dokterspesialis: items.dokterspesialis
    });
  }

  handleClickSpesialis = (e) => {
    this.props.getDokterSpesialis(e.id);
    this.setState({
      aktifspesialis: e.spesialis
    });
  }

  handleClickJadwal = (jadwal, dokter) => {
    this.props.getJadwalTerpilih(jadwal, dokter);
    this.props.getProfile();
    this.props.history.push('/home/appointment');
  }

  render() {
    let spesialis = <span>Tidak Ada Spesialis</span>;

    if (this.state.spesialis) {
      spesialis = Array.from(this.state.spesialis).map((spesialis, index) => (
        <DaftarSpesialis key={index} clickHandler={this.handleClickSpesialis} value={spesialis}>{spesialis.spesialis}</DaftarSpesialis>
      ));
    }

    let dokterspesialis;
    if (this.state.dokterspesialis.length !== 0) {
      dokterspesialis = <DaftarJadwal clickHandler={this.handleClickJadwal} spesialis={this.state.aktifspesialis} dokter={this.state.dokterspesialis}>{this.state.dokterspesialis}</DaftarJadwal>;
    }else {
      dokterspesialis = <span>Tidak ada jadwal</span>;
    }

    return (
      <React.Fragment>
      <div className="app-main">
        <header className="main-heading">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div className="page-icon">
                  <i className="icon-laptop_windows"></i>
                </div>
                <div className="page-title">
                  <h5>Jadwal Dokter Telemedis</h5>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="main-content">
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="card-header">Spesialis</div>
              <div className="card">
                <div className="card-body">
                  {spesialis}
                </div>
              </div>
            </div>
          </div>

          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  {dokterspesialis}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    spesialis: state.jadwaldokter.spesialis,
    jadwalspesialis: state.jadwaldokter.jadwalspesialis,
    dokterspesialis: state.jadwaldokter.dokterspesialis,
    jadwaldokter: state.jadwaldokter.jadwaldokter
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSpesialis: () => dispatch(getSpesialis()),
    getJadwalSpesialis: (id_spesialis) => dispatch(getJadwalSpesialis(id_spesialis)),
    getDokterSpesialis: (id_spesialis) => dispatch(getDokterSpesialis(id_spesialis)),
    getJadwalDokter: (id_dokter) => dispatch(getJadwalDokter(id_dokter)),
    getJadwalTerpilih: (jadwal, dokter) => dispatch(getJadwalTerpilih(jadwal, dokter)),
    getProfile: () => dispatch(getProfile()),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Jadwal));
