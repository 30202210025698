import React, {Component} from 'react';
import DetailTanggal from './detailtanggal';
import "../../style/jadwal.css";

class DetailJadwal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hari1 : '',
      hari2 : '',
      hari3 : '',
      hari4 : '',
      jadwaldokter  : [],
    }
  }

  componentDidMount() {
    var date1 = new Date(new Date().getTime());
    var date2 = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var date3 = new Date(new Date().getTime() + (24*2) * 60 * 60 * 1000);
    var date4 = new Date(new Date().getTime() + (24*3) * 60 * 60 * 1000);
    var hari1 = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
    var hari2 = ("0" + date2.getDate()).slice(-2) + "/" + ("0" + (date2.getMonth() + 1)).slice(-2) + "/" + date2.getFullYear();
    var hari3 = ("0" + date3.getDate()).slice(-2) + "/" + ("0" + (date3.getMonth() + 1)).slice(-2) + "/" + date3.getFullYear();
    var hari4 = ("0" + date4.getDate()).slice(-2) + "/" + ("0" + (date4.getMonth() + 1)).slice(-2) + "/" + date4.getFullYear();

    this.setState({
      hari1: hari1,
      hari2: hari2,
      hari3: hari3,
      hari4: hari4,
    })

    console.log(this.state.hari1);
    console.log(this.state.hari2);
    console.log(this.state.hari3);
    console.log(this.state.hari4);
  }

  render() {
    let hari1 = "";
    let hari2 = "";
    let hari3 = "";
    let hari4 = "";

    if (this.props.children.length !== 0) {
      this.props.children.forEach((jadwaldokter, index) => {
        let tanggal_praktek = jadwaldokter.tanggal_praktek+" ( "+jadwaldokter.waktu_mulai+" s/d "+jadwaldokter.waktu_selesai+" )";
        if (jadwaldokter.tanggal_praktek === this.state.hari1) {
          hari1 = <DetailTanggal clickHandler={this.props.clickHandler} jadwal={jadwaldokter} spesialis={this.props.spesialis} dokter={this.props.dokter}>{tanggal_praktek}</DetailTanggal>;
        }else if (jadwaldokter.tanggal_praktek === this.state.hari2) {
          hari2 = <DetailTanggal clickHandler={this.props.clickHandler} jadwal={jadwaldokter} spesialis={this.props.spesialis} dokter={this.props.dokter}>{tanggal_praktek}</DetailTanggal>;
        }else if (jadwaldokter.tanggal_praktek === this.state.hari3) {
          hari3 = <DetailTanggal clickHandler={this.props.clickHandler} jadwal={jadwaldokter} spesialis={this.props.spesialis} dokter={this.props.dokter}>{tanggal_praktek}</DetailTanggal>;
        }else if (jadwaldokter.tanggal_praktek === this.state.hari4) {
          hari4 = <DetailTanggal clickHandler={this.props.clickHandler} jadwal={jadwaldokter} spesialis={this.props.spesialis} dokter={this.props.dokter}>{tanggal_praktek}</DetailTanggal>;
        }
      })
    }
    return (
      <React.Fragment>
        <td><span className="badge badge-pill badge-primary">{hari1}</span></td>
        <td><span className="badge badge-pill badge-primary">{hari2}</span></td>
        <td><span className="badge badge-pill badge-primary">{hari3}</span></td>
        <td><span className="badge badge-pill badge-primary">{hari4}</span></td>
      </React.Fragment>
    );
  }
}

export default (DetailJadwal);
