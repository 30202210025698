import React, {Component} from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer fixed-btm">
				Copyright &copy; IT RS AN-NISA & DKonsul 2020.
			</footer>
    );
  }
}

export default Footer;
