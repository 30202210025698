import React, {Component, Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {listAppointment} from '../../store/actions/AppointmentAction'
import {setAppointment} from '../../store/actions/AppointmentAction'
import {setKonsultasi} from '../../store/actions/AppointmentAction'
import {getProfile} from '../../store/actions/AuthAction';
import ListHistory from './listhistory'
import { Modal, Button } from 'react-bootstrap'

class HistoriAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listregistrasi : [],
      data_registrasi : [],
      show : false,
    }
  }

  componentDidMount () {
    // console.log(this.props.profile);
    // console.log(this.props.appointment);
    this.props.getProfile();
    this.props.listAppointment();
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props);
    return{
      listregistrasi: props.listregistrasi,
    };
  }

 handleClickKonsul = async (action, data_registrasi) => {
    if (action === 0) {
      await this.props.setAppointment(data_registrasi);
      await this.props.history.push('/home/asesmenawal');
    }else {
      this.setState ({
        data_registrasi : data_registrasi
      })
      this.handleShow()
      // this.props.history.push('/home/konsul');
    }
  }

  handleToKonsul = async () => {
    await this.props.setKonsultasi(this.state.data_registrasi);
    await this.props.history.push('/home/konsul');
  }

  handleClose = () => {
    this.setState ({
      show : false
    })
  }

  handleShow  = () => {
    this.setState ({
      show : true
    })
  }

  render () {
    // console.log(this.state.listregistrasi);
    let listregistrasi;
    if (this.state.listregistrasi.length !== 0) {
      listregistrasi = <ListHistory clickHandler={this.handleClickKonsul}>{this.state.listregistrasi}</ListHistory>;
    }else {
      listregistrasi = <span>Tidak ada data</span>;
    }

    return (
      <Fragment>
      <div className="app-main">
        <header className="main-heading">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div className="page-icon">
                  <i className="icon-laptop_windows"></i>
                </div>
                <div className="page-title">
                  <h5>Histori Appointment</h5>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="main-content">
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card-header">Histori Appointment</div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                    <div className="row no-gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        {listregistrasi}
                      </div>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Informasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Anda akan di arahkan ke portal telekonsultasi dengan dokter. Di dalam portal tersebut anda akan
            memilih spesialis dan dokter kembali. Pembayaran pun akan dilakukan di dalam portal tersebut.
          </p>
          <p><strong>Harap pilih spesialis dan dokter yang sesuai dengan jadwal yang sudah anda pilih.</strong></p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleToKonsul}>
            Masuk
          </Button>
          <Button variant="secondary" onClick={this.handleClose}>
            Batal
          </Button>
        </Modal.Footer>
      </Modal>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listregistrasi : state.appointment.listAppointment,
    appointment : state.appointment.appointment
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAppointment: () => dispatch(listAppointment()),
    setAppointment: (data_regist) => dispatch(setAppointment(data_regist)),
    setKonsultasi: (data_regist) => dispatch(setKonsultasi(data_regist)),
    getProfile: () => dispatch(getProfile()),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HistoriAppointment));
