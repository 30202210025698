import React, {Component} from 'react';
// import {connect} from 'react-redux';

class ListHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tanggal_praktek : "",
    }
  }

  render() {
    let status;
    var today = new Date();
    // var time = today.getHours() + ":" + today.getMinutes();
    // var status_asesmen;

    // format date
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();
    // var hari_ini = dd + '/' + mm + '/' + yyyy;

    status = Array.from(this.props.children).map((detailregist, index) => {
      var status_asesmen;
      if (detailregist.status === "Belum Asesmen") {
        status_asesmen = 0
      }else if (detailregist.status === "Sudah Asesmen") {
        status_asesmen = 1
      }else {
        status_asesmen = 2
      }

      // convert jadwal_praktek to date
      var tanggal = detailregist.jadwaltelemedis.tanggal_praktek.split('/');
      var jam_mulai = detailregist.jadwaltelemedis.waktu_mulai.split(':');
      var jam_selesai = detailregist.jadwaltelemedis.waktu_selesai.split(':');
      var jadwal_praktek_mulai = new Date(tanggal[2], tanggal[1] - 1, tanggal[0], jam_mulai[0], jam_mulai[1]);
      var jadwal_praktek_selesai = new Date(tanggal[2], tanggal[1] - 1, tanggal[0], jam_selesai[0], jam_selesai[1]);

      console.log(jam_mulai + " - " + jam_selesai);

      // if (today.getTime() > jadwal_praktek_selesai.getTime()) {
      //   console.log("Sudah Selesai " + jadwal_praktek_selesai.toDateString());
      // }else if (today.getTime() > jadwal_praktek_mulai.getTime()) {
      //   console.log("Menunggu Jadwal " + jadwal_praktek_mulai.toDateString());
      // }

      return (
        <tr key={index}>
          <td>{detailregist.jadwaltelemedis.spesialis}</td>
          <td>{detailregist.jadwaltelemedis.nama}</td>
          <td>{detailregist.jadwaltelemedis.tanggal_praktek} ( {detailregist.jadwaltelemedis.waktu_mulai} s/d {detailregist.jadwaltelemedis.waktu_selesai} )</td>
          {(detailregist.status === "Belum Asesmen"
              ? <React.Fragment><td><span className="badge badge-pill badge-warning">{detailregist.status}</span></td>
                                </React.Fragment>
              : detailregist.status === "Sudah Asesmen"
                ? <React.Fragment><td><span className="badge badge-pill badge-success">{detailregist.status}</span></td>
                                  </React.Fragment>
                : <React.Fragment><td><span className="badge badge-pill badge-danger">{detailregist.status}</span></td>
                                  </React.Fragment>
          )}

          {
            (today.getTime() > jadwal_praktek_selesai.getTime()
              ? <td><span className="badge badge-pill badge-success">Jadwal Selesai</span></td>
              : today.getTime() < jadwal_praktek_mulai.getTime()
                ? <td><span className="badge badge-pill badge-success">Menunggu Jadwal</span></td>
                : (status_asesmen === 0
                  ? <React.Fragment><td><button type="button" className="btn btn-primary btn-rounded" onClick={this.props.clickHandler.bind(this, 0, detailregist)}><span className="icon-arrow-forward"></span>Form Asesmen</button></td></React.Fragment>
                  : status_asesmen === 1
                    ? <React.Fragment><td><button type="button" className="btn btn-primary btn-rounded" onClick={this.props.clickHandler.bind(this, 1, detailregist)}><span className="icon-arrow-forward"></span>Konsultasi</button></td></React.Fragment>
                    : <React.Fragment><td><span className="badge badge-pill badge-danger">{detailregist.status}</span></td></React.Fragment>
                )
            )
          }
        </tr>
      );
    });

    return (
      <React.Fragment>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Spesialis</th>
              <th>Dokter</th>
              <th>Jadwal</th>
              <th>Status Asesmen</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {status}
          </tbody>
        </table>
      </div>
      </React.Fragment>
    );
  }
}

export default ListHistory;
