import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
// import {getProfile} from '../../store/actions/AuthAction';
import {addAssesmen} from '../../store/actions/AsesmenAwalAction';
import { Modal, Button } from 'react-bootstrap';
import "./style.css";

class AsesmenAwal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_registrasi : this.props.appointment.id,
      data_diperoleh : '',
      kebutuhan : '',
      keluhan : '',
      riwayat_sebelumnya : '',
      tekanan_darah : '',
      suhu_badan : '',
      berat_badan : '',
      persetujuan_general_consent : '',
      hide_class : 'd-none',
      riwayat_sebelumnya_lain_lain : false,
      appointment : [],
      show : false,
    }
    this.Modal = React.createRef();
  }

  componentDidMount() {
    // console.log(this.props.appointment);
    var size = this.Objectsize(this.props.appointment);
    if (size === 0) {
      this.props.history.push('/home/historiappointment');
    }
  }

  componentWillReceiveProps(items) {
    this.setState({
      id_registrasi: items.appointment.id,
      appointment: items.appointment,
    });
  }

  handleChange = (e) => {
    this.setState ({
      [e.target.id] : e.target.value
    })
  };

  handleChangeRiwayatSebelumnya = (e) => {
    if (e.target.value === "") {
      this.setState ({
        [e.target.id] :"",
        hide_class : "",
        riwayat_sebelumnya_lain_lain : true,
      })
    }else {
      this.setState ({
        [e.target.id] : e.target.value,
        hide_class : "d-none",
        riwayat_sebelumnya_lain_lain : false,
      })
    }
  };

  handleChangeRiwayatSebelumnyaLainLain = (e) => {
    this.setState ({
      riwayat_sebelumnya : e.target.value
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);
    // this.props.addAssesmen(this.state,this.props.history)
    this.handleShow();
  };

  handlesubmitGeneralConsent = (status) => {
    this.setState ({persetujuan_general_consent : status}, () => {
        this.props.addAssesmen(this.state,this.props.history)
    })
  }

  handleClose = () => {
    this.setState ({
      show : false
    })
  }

  handleShow  = () => {
    this.setState ({
      show : true
    })
  }

  Objectsize = (obj) => {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  render () {
    return (
      <Fragment>
          <div className="app-main">
            <header className="main-heading">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div className="page-icon">
                      <i className="icon-laptop_windows"></i>
                    </div>
                    <div className="page-title">
                      <h5>Assesmen Awal</h5>
                      <h6 className="sub-heading">Buat Janji dengan dokter</h6>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="main-content">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card-header">Assesmen Awal</div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row no-gutters">
                          <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>Data Diperoleh dari</label>
                              <input type="hidden" className="form-control" id="id_registrasi" name="id_registrasi" value={this.state.id_registrasi} onChange={this.handleChange}  />
                              <select className="form-control" id="data_diperoleh" name="data_diperoleh" defaultValue='no-value' onChange={this.handleChange} required>
                                <option disabled value="no-value">-- Pilih --</option>
                                <option value="Saya Sendiri">Saya Sendiri</option>
                                <option value="Suami/Istri">Suami/Istri</option>
                                <option value="Anak">Anak</option>
                                <option value="Saudara">Saudara</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Kebutuhan saat ini</label>
                              <select className="form-control" id="kebutuhan" name="kebutuhan" defaultValue='no-value' onChange={this.handleChange} required>
                                <option disabled value="no-value">-- Pilih --</option>
                                <option value="Kontrol Rutin">Kontrol Rutin</option>
                                <option value="Ada Keluhan Baru">Ada Keluhan Baru</option>
                                <option value="Obat Habis">Obat Habis</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Keluhan Saat Ini</label>
                              <textarea className="form-control" id="keluhan" name="keluhan" onChange={this.handleChange} required></textarea>
                            </div>
                            <div className="form-group">
                              <label>Riwayat Kesehatan Sebelumnya</label>
                              <select className="form-control" id="riwayat_sebelumnya" name="riwayat_sebelumnya" defaultValue='no-value' onChange={this.handleChangeRiwayatSebelumnya} required>
                                <option disabled value="no-value">-- Pilih --</option>
                                <option value="Hipertensi">Hipertensi/Tensi Tinggi</option>
                                <option value="Diabetes Melitus">Diabetes Melitus/Gula Darah</option>
                                <option value="Jantung">Jantung</option>
                                <option value="Gagal Ginjal">Gagal Ginjal</option>
                                <option value="Stroke">Stroke</option>
                                <option value="">Lain lain ...</option>
                              </select>
                              <br/>
                              <input type="text" className={'form-control ' + this.state.hide_class} id="riwayat_sebelumnya_lain_lain" name="riwayat_sebelumnya_lain_lain" onChange={this.handleChangeRiwayatSebelumnyaLainLain}  placeholder="Riwayat kesehatan Sebelumnya" required={this.state.riwayat_sebelumnya_lain_lain} />
                            </div>
                            <div className="form-group">
                              <label>Tekanan darah</label>
                              <input type="text" className="form-control" id="tekanan_darah" name="tekanan_darah" onChange={this.handleChange}  placeholder="contoh: 120/90" required />
                            </div>
                            <div className="form-group">
                              <label>Suhu Badan</label>
                              <input type="text" className="form-control" id="suhu_badan" name="suhu_badan" onChange={this.handleChange}  placeholder="Suhu Badan" required />
                            </div>
                            <div className="form-group">
                              <label>Berat Badan</label>
                              <input type="text" className="form-control" id="berat_badan" name="berat_badan" onChange={this.handleChange}  placeholder="Berat Badan" required />
                            </div>
                            <div className="actions clearfix">
                              <button type="submit" className="btn btn-primary">Simpan</button>
                            </div>
                          </div>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <Modal ref={el => (this.Modal = el)}
            size="lg"
            show={this.state.show}
            onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Persetujuan General Consent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>General Consent</h3>
            <p>
              Telemedicine adalah proses konsultasi kesehatan TANPA melakukan pemeriksaan fisik langsung terhadap  pasien oleh tenaga kesehatan ( dokter dan perawat) dalam sesi pelayanannya. Telemedicine melibatkan penggunaan komunikasi elektronik untuk memungkinkan penyedia layanan kesehatan di lokasi yang berbeda untuk berbagi informasi medis pada masing-masing pasien dengan tujuan meningkatkan pelayanan kesehatan pasien. Penyedia layanan mencakup praktisi dokter umum, spesialis, dan / atau spesialis subspesialis.
            </p>
            <p>
              Informasi yang didapatkan tersebut dapat digunakan untuk menegakkan diagnosis, terapi, tindak lanjut dan / atau edukasi kesehatan dan dapat mencakup salah satu dari hal berikut:
            </p>
            <ol className="alpha">
              <li>Catatan medis pasien</li>
              <li>Gambar kondisi medis maupun gambar pencitraan medis</li>
              <li>Audio dan video dua arah langsung</li>
              <li>Data output dari perangkat medis serta file  suara dan file video. </li>
            </ol>

            <p>
            Sistem elektronik yang digunakan akan menggabungkan protokol keamanan jaringan dan perangkat lunak untuk melindungi kerahasiaan identifikasi pasien dan  data pencitraan, serta akan mencakup langkah-langkah untuk melindungi data untuk memastikan integritas keamanannya terhadap penyalahgunaan data  yang disengaja atau tidak disengaja.
            </p>

            <h6>Manfaat yang Diharapkan:</h6>
            <ol className="alpha">
              <li>Peningkatan akses ke perawatan medis dengan memungkinkan pasien untuk tetap berada di pelayanan kesehatan maupun di rumah (atau di lokasi terpencil) sementara dokter mendapatkan hasil tes dan berkonsultasi dengan praktisi kesehatan di tempat yang jauh / di tempat lain.</li>
              <li>Evaluasi dan manajemen medis yang lebih efisien.</li>
              <li>Memperoleh hasil expertise dari spesialis bidang tertentu yang tidak berada di area pelayanan kesehatan.</li>
            </ol>

            <h6>Kemungkinan Resiko :</h6>
            <p>
              Seperti halnya prosedur medis, ada risiko potensial yang terkait dengan penggunaan telemedicine. Risiko-risiko ini termasuk, tetapi mungkin tidak terbatas pada :
            </p>
            <ol className="alpha">
              <li>Dalam kasus yang jarang terjadi, informasi yang dikirimkan mungkin tidak cukup (mis. Resolusi gambar yang buruk) untuk memungkinkan pengambilan keputusan medis yang tepat oleh dokter dan konsultan;</li>
              <li>Keterlambatan evaluasi dan perawatan medis dapat terjadi karena kekurangan atau kegagalan peralatan;</li>
              <li>Dalam kasus yang sangat jarang, protokol keamanan bisa gagal, menyebabkan pelanggaran privasi informasi medis pribadi;</li>
              <li>Dalam kasus yang jarang terjadi, kurangnya akses ke rekam medis lengkap dapat mengakibatkan interaksi obat yang merugikan atau reaksi alergi atau kesalahan penilaian lainnya;</li>
            </ol>

            <hr />
            <p>
              Dengan menandatangani formulir ini, saya memahami hal-hal  berikut:
            </p>
            <ol>
              <li>Saya memahami dan menyetujui penjelasan dan persyaratan mengenai Telemedicine di General Consent ini</li>
              <li>Saya memahami bahwa undang-undang yang melindungi privasi dan kerahasiaan informasi medis juga berlaku untuk telemedicine, dan bahwa tidak ada informasi yang diperoleh dalam penggunaan telemedicine yang mengidentifikasi saya akan diungkapkan kepada peneliti atau entitas lain tanpa persetujuan saya.</li>
              <li>Saya mengerti bahwa saya memiliki hak untuk menahan atau menarik persetujuan saya untuk penggunaan telemedicine selama perawatan saya setiap saat, tanpa mempengaruhi hak saya untuk perawatan atau perawatan di masa depan.</li>
              <li>Saya memahami bahwa saya memiliki hak untuk memeriksa semua informasi yang diperoleh dan dicatat selama interaksi telemedicine, dan dapat menerima salinan informasi ini dengan biaya yang rasional.</li>
              <li>Saya memahami bahwa saya wajib memberikan informasi yang benar, jelas, dapat dipertanggungjawabkan, dan jujur selama telemedicine dilakukan.</li>
              <li>Saya memahami bahwa informasi yang saya berikan, dapat berupa catatan medis , gambar kondisi medis , gambar pencitraan medis , rekaman audio dan video dua arah langsung, data output dari perangkat medis serta file  suara dan file video akan terdokumentasi dalam catatan medis rumah sakit.</li>
              <li>Saya mengerti bahwa sesuai dengan Undang-undang Praktik Kedokteran dan ITE maka saya DILARANG mendokumentasikan/ memotret/ merekam proses tindakan konsultasi medis/ keperawatan selama telemedicine berlangsung dengan cara apapun dan dengan alasan apapun tanpa izin tertulis dari Rumah Sakit.</li>
              <li>Saya mengerti bahwa berbagai metode alternatif perawatan medis mungkin tersedia untuk saya, dan bahwa saya dapat memilih satu atau lebih dari ini setiap saat.</li>
              <li>Saya mengerti bahwa telemedicine dapat melibatkan komunikasi elektronik dari informasi medis pribadi saya kepada praktisi medis lain yang mungkin berlokasi di area lain, termasuk di luar negari</li>
              <li>Saya mengerti bahwa adalah tugas saya untuk memberi tahu dokter saya tentang interaksi elektronik mengenai perawatan saya yang mungkin saya miliki dengan penyedia layanan kesehatan lainnya.</li>
              <li>Saya mengerti bahwa saya dapat mengharapkan manfaat dari penggunaan telemedicine dalam perawatan saya, tetapi tidak ada hasil yang dapat menjamin atau dijamin.</li>
              <li>Saya memahami bahwa telemedicine memiliki keterbatasan atas penegakan diagnosis terhadap keluhan medis saya, dan karenanya mungkin akan dibutuhkan pemeriksaan lebih lanjut di rumah sakit.</li>
            </ol>

            <h6>Persetujuan Pasien Untuk Penggunaan Telemedicine</h6>
            <p>
              Saya telah membaca dan memahami informasi yang diberikan di atas mengenai telemedicine, telah membahasnya dengan dokter atau asisten seperti yang ditunjuk, dan semua pertanyaan saya telah dijawab untuk kepuasan saya. Saya dengan ini memberikan persetujuan untuk penggunaan telemedicine dalam perawatan medis saya.
            </p>
            <p>
              Saya dengan ini mengizinkan dokter yang saya tunjuk (*pilihan sebelumnya) untuk menggunakan telemedicine dalam perjalanan diagnosis dan perawatan saya.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" ref={el => (this.Button = el)} onClick={this.handlesubmitGeneralConsent.bind(this, "Tidak Setuju")}>
              Tidak Setuju
            </Button>
            <Button variant="primary" ref={el => (this.Button = el)} onClick={this.handlesubmitGeneralConsent.bind(this, "Setuju")}>
              Setuju
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointment : state.appointment.appointment,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAssesmen: (data,history) => dispatch(addAssesmen(data,history))
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AsesmenAwal));
