import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';

function Sidebar (props) {
  let { url } = useRouteMatch();

  return (
    <aside className="app-side" id="app-side">
      <div className="side-content ">

        <div className="user-profile">
          <img src="/favicon.png" className="profile-thumb" alt="User Thumb" />
          <ul className="profile-actions">
            <li>
              <Link to="#" onClick={props.logOutHandler}>
                <i className="icon-export"></i>
              </Link>
            </li>
          </ul>
        </div>

        <nav className="side-nav">
          <ul className="unifyMenu" id="unifyMenu">
            <li className="active selected">
              <Link to={`${url}`}>
                <span className="has-icon">
                  <i className="icon-laptop_windows"></i>
                </span>
                <span className="nav-title">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to={`${url}/profil`}>
                <span className="has-icon">
                  <i className="icon-chat_bubble_outline"></i>
                </span>
                <span className="nav-title">Profil</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/jadwal`}>
                <span className="has-icon">
                  <i className="icon-chat_bubble_outline"></i>
                </span>
                <span className="nav-title">Appointment</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/historiappointment`}>
                <span className="has-icon">
                  <i className="icon-chat_bubble_outline"></i>
                </span>
                <span className="nav-title">Histori Appointment</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/konsul`}>
                <span className="has-icon">
                  <i className="icon-chat_bubble_outline"></i>
                </span>
                <span className="nav-title">Konsultasi</span>
              </Link>
            </li>

          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
