import React, {Component} from 'react';

class Home extends Component {
  render() {
    return (
      <div className="app-main">
        <header className="main-heading">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div className="page-icon">
                  <i className="icon-laptop_windows"></i>
                </div>
                <div className="page-title">
                  <h5>Dashboard</h5>
                  {/*<h6 className="sub-heading">Welcome to Unify Admin Template</h6>*/}
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="main-content">
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <h4>Selamat Datang di Telekonsultasi RS AN-NISA Tangerang</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
