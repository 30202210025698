// import React, { useState, useEffect } from 'react';
import {SignUpService} from '../services/AuthService';
import {LoginUser} from '../services/AuthService';
import {DataUser} from '../services/AuthService';

export const signUp = (credentials,history) => {

  return (dispatch) => {
    if (credentials.password.length < 6) {
      return dispatch({type: 'SHORT_PASSOWRD'});
    }

    SignUpService(credentials,history).then((res) => {
      // console.log(res);
      if (res) {
        if (typeof res.token !== 'undefined') {
          localStorage.setItem('user','Bearer '+res.token);
          dispatch({type: 'SIGNUP_SUCCESS'});
          history.push("/home");
        }else {
          dispatch({type: 'SIGNUP_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const UserLogin = (credentials,history) => {
  return (dispatch) => {
    if (credentials.password.length < 6) {
      return dispatch({type: 'SHORT_PASSOWRD'});
    }

    LoginUser(credentials,history).then((res) => {
      // console.log(res);
      if (res) {
        if (typeof res.token !== 'undefined') {
          localStorage.setItem('user','Bearer '+res.token);
          // console.log(res.token);
          dispatch({type: 'LOGIN_SUCCESS', res});
          history.push("/home");
        }else {
          dispatch({type: 'LOGIN_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const getProfile = () => {
  return (dispatch) => {

    DataUser().then((res) => {
      // console.log(res);
      if (res) {
        if (res.success === true) {
          dispatch({type: 'GET_PROFILE_SUCCESS', res});
        }else {
          dispatch({type: 'GET_PROFILE_ERROR', res});
        }
      }else {
        dispatch({type: 'CODE_ERROR'});
      }

    }, error => {
      dispatch({type: 'CODE_ERROR', error});
    })
  };
}

export const logOutUser = (history) => {
  return (dispatch) => {
    localStorage.removeItem('user');
    dispatch({type: 'LOG_OUT_USER'});
    history.push("/login");
  };
}
