import React, {Component} from 'react';
import Footer from '../components/Footer';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {UserLogin} from '../store/actions/AuthAction';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email : '',
      password : ''
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('ready to log a user in');
    // console.log(this.state);
    this.props.UserLogin(this.state,this.props.history)
  }

  handleChange = (e) => {
    this.setState ({
      [e.target.id] : e.target.value
    })
  };

  render() {
    const {authResponse} = this.props;
    return (
      <React.Fragment>
        <div className="container">
          <div className="login-screen row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <form onSubmit={this.handleSubmit}>
                <div className="login-container">
                  <div className="row no-gutters">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                      <div className="login-box">
                        <Link to="/home" className="login-logo">
                          <img src="logoannisa.png" alt="Unify Admin Dashboard" />
                        </Link>
                        <center><b>{authResponse!==null && authResponse!==""?authResponse:null}</b></center><br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-account_circle"></i></span>
                          <input id="email" name="email" type="email" onChange={this.handleChange} className="form-control" placeholder="Email" aria-label="email" aria-describedby="email" autoComplete="email" required autoFocus />
                        </div>
                        <br />
                        <div className="input-group">
                          <span className="input-group-addon"><i className="icon-verified_user"></i></span>
                          <input id="password" name="password" type="password" onChange={this.handleChange} className="form-control" placeholder="Password" aria-label="Password" aria-describedby="password" autoComplete="current-password" required />
                        </div>
                        {/*
                        <div className="form-group">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" name="remember" id="remember" />
                            </label>
                          </div>
                        </div>
                        */}
                        <div className="actions clearfix">
                          {/* <Link to="/forgot-password">Lost password?</Link> */}
                          <button type="submit" className="btn btn-primary">Login</button>
                        </div>
                        <div className="or"></div>
                        <div className="mt-4">
                        <Link to="/register" className="additional-link">Don't have an Account? <span>Create Now</span></Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                      <div className="login-slider"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authResponse: state.auth.authResponse
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    UserLogin: (creds,history) => dispatch(UserLogin(creds,history))
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Login));
