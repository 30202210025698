import React, {Component} from 'react';
import "../../style/jadwal.css";

class DetailTanggal extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    let dokter = {
      spesialis: this.props.spesialis,
      dokter: this.props.dokter,
    }

    return (
      <React.Fragment>
        <span className="badge badge-pill badge-primary" onClick={this.props.clickHandler.bind(this, this.props.jadwal,dokter)}>{this.props.children}</span>
      </React.Fragment>
    );
  }
}

export default (DetailTanggal);
