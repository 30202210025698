import React from 'react';
import {Route, Switch, useRouteMatch, withRouter} from 'react-router-dom';

import Home from './Home';
import Profil from './Profil';
import About from './About';
import Jadwal from './jadwal';
import Konsultasi from './Konsultasi';
import Appointment from './appointment/Appointment';
import HistoriAppointment from './appointment/HistoryAppointment';
import AsesmenAwal from './asesmen_awal/AsesmenAwal';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Login from './Login';

import {logOutUser} from '../store/actions/AuthAction';

import {connect} from 'react-redux';

function Master(props) {
  let { path } = useRouteMatch();

  const logOutHandler = () => {
    // console.log("LogOut");
    props.logOutUser(props.history)
  }

  return (
    <React.Fragment>
        <Header logOutHandler={logOutHandler} />
        <div className="app-container">
          <Sidebar logOutHandler={logOutHandler} />
          <Switch>
            <Route path="/login"><Login /></Route>
            <Route exact path={path} component={Home} />
            <Route path={`${path}/about`} component={About} />
            <Route path={`${path}/profil`} component={Profil} />
            <Route path={`${path}/konsul`} component={Konsultasi} />
            <Route path={`${path}/appointment`} component={Appointment} />
            <Route path={`${path}/asesmenawal`} component={AsesmenAwal} />
            <Route path={`${path}/jadwal`} component={Jadwal} />
            <Route path={`${path}/historiappointment`} component={HistoriAppointment} />
          </Switch>
        </div>
        <Footer />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOutUser: (history) => dispatch(logOutUser(history))
  };
}

export default withRouter(connect(null,mapDispatchToProps)(Master));
