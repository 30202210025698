import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';
import ProfileReducer from './ProfileReducer';
import JadwalReducer from './JadwalReducer';
import AppointmentReducer from './AppointmentReducer';

const RootReducer = combineReducers({
  auth : AuthReducer,
  profile : ProfileReducer,
  jadwaldokter : JadwalReducer,
  appointment : AppointmentReducer
});

export default RootReducer;
