import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getProfile} from '../store/actions/AuthAction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Profil extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jadwalterpilih : [],
      dokterterpilih : [],
      nama : "",
      nik : "",
      tempat_lahir : "",
      tanggal_lahir : "",
      nomor_telepon : "",
      no_kartu : "",
    }
  }

  componentDidMount () {
    this.props.getProfile();
  }

  componentWillReceiveProps(items) {
    this.setState({
      nama : items.profile.nama,
      nik : items.profile.nik,
      tempat_lahir : items.profile.tempat_lahir,
      tanggal_lahir : items.profile.tanggal_lahir,
      nomor_telepon : items.profile.nomor_telepon,
      no_kartu : items.profile.no_rm,
    });
  }

  handleChange = (e) => {
    this.setState ({
      [e.target.id] : e.target.value
    })
  };

  render() {
    return (
      <div className="app-main">
        <header className="main-heading">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <div className="page-icon">
                  <i className="icon-laptop_windows"></i>
                </div>
                <div className="page-title">
                  <h5>Profil</h5>
                  <h6 className="sub-heading">Profile</h6>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="main-content">
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label>NIK</label>
                    <input type="text" className="form-control" id="nik" name="nik" value={this.state.nik || ""} onChange={this.handleChange}  placeholder="NIK" />
                  </div>
                  <div className="form-group">
                    <label>Nama</label>
                    <input type="text" className="form-control" id="nama" name="nama" value={this.state.nama || ''} onChange={this.handleChange}  placeholder="Nama" />
                  </div>
                  <div className="form-group">
                    <label>Tempat Lahir</label>
                    <input type="text" className="form-control" id="tempat_lahir" name="tempat_lahir" value={this.state.tempat_lahir || ''} onChange={this.handleChange}  placeholder="Tempat Lahir" />
                  </div>
                  <div className="form-group">
                    <label>Tanggal lahir</label>
                    <DatePicker id="tanggal_lahir" className="form-control-date" placeholderText="Tanggal Lahir" dateFormat="dd/MM/yyyy" value={this.state.tanggal_lahir || new Date()} showMonthDropdown showYearDropdown dropdownMode="select" maxDate={new Date()} selected={new Date()} onChange={this.handleChangeDate} />
                  </div>
                  <div className="form-group">
                    <label>No. Telp/HP</label>
                    <input type="text" className="form-control" id="nomor_telepon" name="nomor_telepon" value={this.state.nomor_telepon || ''} onChange={this.handleChange}  placeholder="No. Telp/HP" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile : state.auth.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile())
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Profil);
