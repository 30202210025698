const initState = {
  authResponse : null,
  profile : [],
  email : null
};

const AuthReducer = (state=initState, action) => {
  switch(action.type){
    case 'SHORT_PASSOWRD':
      // console.log(action);
      return {
        ...state,
        authResponse : 'Password is too short.'
      };

    case 'SIGNUP_SUCCESS':
      // console.log(action);
      return {
        ...state,
        authResponse : 'Signup was successfully done.'
      };

    case 'SIGNUP_ERROR':
      // console.log(action);
      return {
        ...state,
        authResponse : action.res.message
      };

    case 'CODE_ERROR':
      // console.log(action);
      return {
        ...state,
        authResponse : 'There seems to be a problem please try again later.'
      };

    case 'LOGIN_SUCCESS':
      // console.log(action);
      return {
        ...state,
        profile : action.res.user_data,
        authResponse : 'Login Success.'
      };

    case 'LOGIN_ERROR':
      // console.log(action);
      return {
        ...state,
        authResponse : action.res.message
      };

    case 'GET_PROFILE_SUCCESS':
      // console.log(action);
      return {
        ...state,
        profile : action.res.user_data,
        email : action.res.user_email,
        authResponse : 'Login Success.'
      };

    case 'GET_PROFILE_ERROR':
      // console.log(action);
      return {
        ...state,
        authResponse : "Cannot get data User"
      };

    case 'LOG_OUT_USER':
      // console.log(action);
      return {
        ...state,
        authResponse : "Log Out Success"
      };

    default:
      return state;

  }
}

export default AuthReducer;
