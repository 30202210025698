import HttpServices from './HttpServices';

export const getSpesialisService = () => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let getSpesialisUrl = "jadwal/spesialis";
  return http.getData(getSpesialisUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}

export const getJadwalSpesialisService = (id_spesialis) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let getJadwalSpesialisUrl = "jadwal/jadwalspesialis?id_spesialis="+id_spesialis;
  return http.getData(getJadwalSpesialisUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}

export const getDokterSpesialisService = (id_spesialis) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let getDokterSpesialisUrl = "jadwal/dokterspesialis?id_spesialis="+id_spesialis;
  return http.getData(getDokterSpesialisUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}

export const getJadwalDokterService = (id_dokter) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let getJadwalDokterUrl = "jadwal/jadwaldokter?id_dokter="+id_dokter;
  return http.getData(getJadwalDokterUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}
