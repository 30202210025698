import HttpServices from './HttpServices';

export const AsesmenAwalService = (data,propsHistory) => {
  const http = new HttpServices();
  // console.log(HttpServices);
  let asesmenAwalUrl = "appointment/asesmen_awal";
  return http.postData(data, asesmenAwalUrl).then(data => {
    // console.log(JSON.stringify(data));
    return data;
  }).catch(error => console.log(error));
}
