import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
// import Header from '../components/Header';
import {connect} from 'react-redux';
import {getProfile} from '../store/actions/AuthAction';

class Konsultasi extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iframe : "http://rsannisa.co.id/",
      nama : this.props.profile.nama,
      email : this.props.email,
    }

    console.log(this.props.email);
  }

  componentDidMount() {
    var size = this.Objectsize(this.props.konsultasi);
    if (size === 0) {
      this.props.history.push('/home/historiappointment');
    }else {
      this.CryptoJS();

    }

  }

  Objectsize = (obj) => {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  CryptoJS() {
    var account = this.state.email+"#"+this.state.nama
    var cipher = cryptAuth.encrypt(account);
    var chatAuth = cipher.replace(/[\/]/g, '@1@');
    var chatAuth2 = chatAuth.replace(/[=]/g, '@2@');
    var chatAuth3 = chatAuth2.replace(/[+]/g, '@3@');

    var decrypt = cryptAuth.decrypt(cipher);
    console.log(cipher);
    console.log(chatAuth3);

    this.setState({
      iframe: "https://m.goapotik.com/konsultasi-dokter?merchantchat=4415&header=0&footer=0&services=1&fortype=2&allow=\"camera https://m.goapotik.com;microphone https://m.goapotik.com\"&auth=" + chatAuth3
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="app-main">
          <iframe allow="camera *;microphone *;geolocation *" src={this.state.iframe} height="1000" width="100%" title="Dkonsul"></iframe>
        </div>
      </React.Fragment>
    );
  }
}

let cryptAuth = {
   key: CryptoJS.enc.Utf8.parse('F18AB33A57F9B229CC9C250D00FC3273'),
   iv: CryptoJS.enc.Utf8.parse('D959B836CD9FB162'),
   encrypt: function (clear){
     var cipher = CryptoJS.AES.encrypt(clear, cryptAuth.key, {
       iv: cryptAuth.iv,
       mode: CryptoJS.mode.CTR
     });
     cipher = cipher.toString();
     return cipher;
   },
   decrypt: function (cipher) {
     var decipher = CryptoJS.AES.decrypt(cipher, cryptAuth.key, {
       iv: cryptAuth.iv,
       mode: CryptoJS.mode.CTR
     });
     decipher = decipher.toString(CryptoJS.enc.Utf8);
     return decipher;
   }
}

const mapStateToProps = (state) => {
  return {
    konsultasi : state.appointment.konsultasi,
    profile : state.auth.profile,
    email : state.auth.email,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile())
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Konsultasi);
